import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "pages/Login/userSlice";
import homeReducer from "pages/Home/homeSlice";
import contractReducer from 'pages/Contracts/contractsSlice';
import codesReducer from 'pages/Contracts/codesSlice';

export const store = configureStore({
	reducer: {
		user: userReducer,
		credits: homeReducer,
		contract: contractReducer,
		codes: codesReducer
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
