import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import LoadScreen from "./LoadScreen";

import "./App.css";
import { store } from "./app/store";
import theme from "./themeConfig";

const Login = lazy(() => import("./pages/Login"));
const Dev = lazy(() => import("./pages/Dev"));
const Home = lazy(() => import("./pages/Home"));
const PDF = lazy(() => import("./pages/PDF"));
const Contracts = lazy(() => import("./pages/Contracts"))

function App() {
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<Router>
					<Suspense fallback={<LoadScreen />}>
						<Switch>
							<Route exact path="/" component={Login} />
							<Route exact path="/dev" component={Dev} />
							<Route exact path="/home" component={Home} />
							<Route exact path="/contracts/:id" component={Contracts} />
							<Route
								exact
								path="/pdf/:op?/:NoControl?"
								component={PDF}
							/>
						</Switch>
					</Suspense>
				</Router>
			</ThemeProvider>
		</Provider>
	);
}

export default App;
