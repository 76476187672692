import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";
import { BackendContractsResponse, ContractInfo, GetContractInfoPayload } from "./interfaces";
import { AXIOS_CONFIG, BACKEND, DIGITAL_PROCUREMENT_URL } from "config/config";

const initialState: ContractInfo = {
	NombreCliente: "",
	NoControl: "",
	url: "",
	cel: 0,
};

const contractsSlice = createSlice({
	name: "contracts",
	initialState,
	reducers: {
		getUrl: (state, action: PayloadAction<BackendContractsResponse>) => {
			state.url = action.payload.url;
		},
		getClient: (state, action: PayloadAction<GetContractInfoPayload>) => {
			if (action.payload.contract) {
				state.cel = action.payload.contract.cel;
				state.NoControl = action.payload.contract.NoControl;
			}
		},
	},
});

export const { getUrl, getClient } = contractsSlice.actions;
export default contractsSlice.reducer;

export const getURL =
	(NoControl: string): AppThunk =>
	(dispatch, getState): void => {
		const body = {
			NoControl,
		};
		axios.post(`${BACKEND}/auth/login`, body, AXIOS_CONFIG).then((res) => {
			let data: BackendContractsResponse = res.data;
			dispatch(getUrl(data));
		});
	};

export const getClientInfo =
	(id: string): AppThunk =>
	(dispatch, getState): void => {
		console.log(`Connected to: ${DIGITAL_PROCUREMENT_URL}`);
		const body = {
			id,
		};
		axios.post(`${DIGITAL_PROCUREMENT_URL}/contracts/get`, body, AXIOS_CONFIG).then((res) => {
			let data: GetContractInfoPayload = res.data;
			console.log(data);
			dispatch(getClient(data));
		});
	};
