import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoadScreen = () => {
	return (
		<div
			style={{
				minWidth: "98vw",
				minHeight: "98.5vh",
				textAlign: "center",
				display: "flex",
				flexWrap: "wrap",
				alignItems: "center",
				justifyContent: "center",
                alignContent: "center"
			}}
		>
			<Loader
				type="MutatingDots"
				color="#3b6bb4"
				secondaryColor="#939598"
				height={100}
				width={100}
				//timeout={3000} //3 secs
			/>
		</div>
	);
};

export default LoadScreen;
