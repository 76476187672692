import axios from "axios";
import { BACKEND, AXIOS_CONFIG } from "config/config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { UserState } from "./interfaces";

const initialState: UserState = {
	loggedIn: false,
	error: false,
	msg: "",
	token: "",
	userInfo: null,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, action: PayloadAction<UserState>) => {
			if (action.payload.loggedIn) {
				state.loggedIn = action.payload.loggedIn;
				state.error = action.payload.error;
				state.msg = action.payload.msg;
				state.token = action.payload.token;
				state.userInfo = action.payload.userInfo;
			}
		},
		logout: state => initialState
	},
});

export const { login, logout } = userSlice.actions;

export const handleLogin =
	(user: { curp: string; password: string }): AppThunk =>
	async (dispatch, getState): Promise<void> => {
		axios
			.post(`${BACKEND}/auth/login`, user, AXIOS_CONFIG)
			.then((res) => {
				let data: UserState = res.data;
				dispatch(login(data));
			});
	};

export const handleLogout =
	(): AppThunk =>
	(dispatch, getState): void => {
		dispatch(logout())
	};

// export user selector to get the slice in any component
export const usersSelector = (state: RootState) => state.user;
// export The reducer
const userReducer = userSlice.reducer;
export default userReducer;
