import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";
import { AXIOS_CONFIG, DIGITAL_PROCUREMENT_URL } from "config/config";
import { BackendSendCodeResponse, BackendValidateCodeResponse, CodesInfo } from "./interfaces";

const initialState: CodesInfo = {
	cel: 0,
	code: "",
	sended: false,
	status: "pending",
	error: false,
	msg: "",
	url: "",
};

const codesSlice = createSlice({
	name: "cel",
	initialState,
	reducers: {
		send: (
			state,
			action: PayloadAction<{ sended: boolean; cel: number }>
		) => {
			if (action.payload.sended) {
				state.sended = true;
				state.cel = action.payload.cel;
			}
		},
		validate: (
			state,
			action: PayloadAction<BackendValidateCodeResponse>
		) => {
			if (action.payload.status === "approved") {
				state.url = action.payload.url;
				state.status = action.payload.status;
				return;
			}
			state.error = true;
			state.msg = action.payload.msg;
		},
	},
});

export const { send, validate } = codesSlice.actions;

export default codesSlice.reducer;

export const validateCode =
	(cel: number, code: string, NoControl: string): AppThunk =>
	(dispatch, getState) => {
		const body = {
			cel,
			code,
			NoControl,
		};
		axios
			.post(`${DIGITAL_PROCUREMENT_URL}/codes/validate`, body, AXIOS_CONFIG)
			.then((res) => {
				let data: BackendValidateCodeResponse = res.data;
				dispatch(validate(data));
			});
	};

export const sendCode =
	(cel: number): AppThunk =>
	async (dispatch, getState) => {
		const body = {
			cel,
		};
		axios
			.post(`${DIGITAL_PROCUREMENT_URL}/codes/send`, body, AXIOS_CONFIG)
			.then((res) => {
				let data: BackendSendCodeResponse = res.data;
				if (data.error) {
					dispatch(
						send({
							sended: false,
							cel,
						})
					);
					return;
				}
				dispatch(send({
					sended: true,
					cel,
				}))
			})
			.catch((err) => {
				dispatch(
					send({
						sended: false,
						cel,
					})
				);
			});
	};
