import axios from "axios";
import { BACKEND, AXIOS_CONFIG } from "config/config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BackendCreditsResponse, HomeState } from "./interfaces";
import { AppThunk } from "app/store";

const initialState: HomeState = {
	error: false,
	msg: "",
	founded: false,
	creditos: 0,
	detalle: [],
};

const homeSlice = createSlice({
	name: "home",
	initialState,
	reducers: {
		getCredits: (state, action: PayloadAction<HomeState>) => {
			if (action.payload.founded) {
				state.error = action.payload.error;
				state.msg = action.payload.msg;
				state.founded = action.payload.founded;
				state.creditos = action.payload.creditos;
				state.detalle = action.payload.detalle;
			}
		},
	},
});

export const { getCredits } = homeSlice.actions;

export const fetchHome =
	(IDCliente: string): AppThunk =>
	async (dispatch, getState): Promise<void> => {
		const body = {
			IDCliente,
		};
		axios
			.post(`${BACKEND}/credits/list`, body, AXIOS_CONFIG)
			.then((res) => {
				let data: BackendCreditsResponse = res.data;
				if (data.error) {
					return dispatch(
						getCredits({
							error: true,
							msg: data.msg,
							founded: false,
							creditos: 0,
							detalle: [],
						})
					);
				}
				const creditsInfo: HomeState = {
					error: false,
					msg: data.msg,
					founded: !data.error,
					creditos: data.credits,
					detalle: data.details,
				};
				return dispatch(getCredits(creditsInfo));
			});
	};

export default homeSlice.reducer;
