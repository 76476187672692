import { createMuiTheme } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows"
// Azul oscuro logo: #3b6bb4

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#3b6bb4",
		},
		secondary: {
			main: "#939598",
		},
	},
	typography: {
		fontFamily: `'Lato', 'sans-serif'`,
		fontSize: 12,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 700,
	},
	shadows: Array(25).fill('none') as Shadows,
});

export default theme;
